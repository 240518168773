<script>
export default {
  name: 'InviteImage'
}
</script>

<template>
  <v-img
    :aspect-ratio="gcInvite_inviteInfo.event_image_invite_ratio"
    :src="gcInvite_inviteInfo.event_image_invite_src"
    contain width="100%">
    <template v-slot:placeholder>
      <v-skeleton-loader height="100%" type="image" width="100%" />
    </template>
  </v-img>
</template>
