import { state } from '../store'

export default {
  gcInvite_submitting: {
    get: () => state.gsInvite_submitting,
    set: value => state.gsInvite_submitting = value,
  },

  gcInvite_submitted: {
    get: () => state.gsInvite_submitted,
    set: value => state.gsInvite_submitted = value,
  },

  gcInvite_inviteInfo: {
    get: () => state.gsInvite_inviteInfo,
    set: value => state.gsInvite_inviteInfo = value,
  },

  gcInvite_teamInfo: {
    get: () => state.gsInvite_teamInfo,
    set: value => state.gsInvite_teamInfo = value,
  },

  gcInvite_team1: {
    get: () => state.gsInvite_team1,
    set: value => state.gsInvite_team1 = value,
  },

  gcInvite_team2: {
    get: () => state.gsInvite_team2,
    set: value => state.gsInvite_team2 = value,
  },

  gcInvite_team3: {
    get: () => state.gsInvite_team3,
    set: value => state.gsInvite_team3 = value,
  },

  gcInvite_teamLabel: {
    get: () => state.gsInvite_teamLabel,
    set: value => state.gsInvite_teamLabel = value,
  },

  gcInvite_formData: {
    get: () => state.gsInvite_formData,
    set: value => state.gsInvite_formData = value,
  },
}