export default {
  // 제출 중 상태
  gsInvite_submitting: false,

  // 제출 함
  gsInvite_submitted: false,

  // 제출 이벤트 정보
  gsInvite_inviteInfo: {},

  // 팀 기본 정보
  gsInvite_teamInfo: {},

  // 팀1
  gsInvite_team1: [],

  // 팀2
  gsInvite_team2: [],

  // 팀3
  gsInvite_team3: [],

  // 팀 레이블 (대중소 순서)
  gsInvite_teamLabel: ['', '', ''],

  // 입력정보
  gsInvite_formData: {
    // 초대 받은자: 이름
    invitee_name: '',
    // 초대 받은자: 회사명
    invitee_company: '',
    // 초대 받은자: 부서
    invitee_part: '',
    // 초대 받은자: 연락처
    invitee_phone: '',
    // 초대 받은자: 이메일
    invitee_email: '',
    // 초대 받은자: 숙박여부
    invitee_stay: '',
    // 초대 받은자: 고객 코드
    invitee_code: '',
    // 초대 받은자: 거래처 코드
    invitee_vendor_code: '',
    // 영업 담당자: 부서(대) id
    sales_part1: -1,
    // 영업 담당자: 부서(대)-직접입력
    sales_part1_typed: '',
    // 영업 담당자: 부서(중) id
    sales_part2: -1,
    // 영업 담당자: 부서(중)-직접입력
    sales_part2_typed: '',
    // 영업 담당자: 부서(소) id
    sales_part3: -1,
    // 영업 담당자: 부서(소)-직접입력
    sales_part3_typed: '',
    // 영업 담당자 이름과 연락처는 필드로 저장되지 않는다. INVITEE_SALESMAN DB에 저장됨
    // 영업 담당자 사원번호
    sales_number: '',
    // 영업 담당자: 이름
    sales_name: '',
    // 영업 담당자: 연락처
    sales_phone: '',
    // 약관 채크 여부
    invitee_terms: false,
    // 커스텀 필드
    invitee_custom_field: [],
  }
}