import Vue from "vue"

import methodsCommon from "./methodsCommon"
import methodsAPI from "./methodsAPI"

import computedCommon from "./computedCommon"
import computedGroup from "./computedGroup"
import computedInvite from "./computedInvite"

const mixin = {
  methods: {
    ...methodsCommon,
    ...methodsAPI,
  },

  computed: {
    ...computedCommon,
    ...computedGroup,
    ...computedInvite,
  }
}

Vue.mixin( mixin )

export default mixin