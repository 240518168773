export default {
  // 페이지 로딩 완료
  gsCommon_pageLoaded: false,

  // 서비스 가동 여부
  gsCommon_serviceState: {
    content: '',
    service_paused: -1,
  },

  // 알림창 객체
  gsCommon_alert: {
    width: 0,
    text: '',
    confirmOkText: '',
    confirmOkColor: '',
    confirmNoText: '',
    confirmNoColor: '',
    defaultOkText: '',
    defaultOkColor: '',
    confirm: false,
    callback: null,
  },

  // html 팝업 객체
  gsCommon_alertHtml: '',

  // 다이알로그 리스트 노출 여부 관리 객체
  gsCommon_dialogShow: {
    alert: false,
    alertHtml: false,
  },

  // 다이알로그 닫아야 할 스택
  gsCommon_dialogStack: [
    // 'alert', 'alertHtml', ...
  ]
}