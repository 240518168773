<template>
  <v-card
    max-width="640"
    min-width="320"
    width="100%"
    tile
  >
    <v-card-title class="pb-0">
      <v-icon class="mx-auto" color="orange" size="128px">mdi-alert-outline</v-icon>
    </v-card-title>
    <v-card-text class="text-pre-wrap text-center black--text text-h6" v-text="gcCommon_serviceState.content"/>
  </v-card>
</template>

<script>
export default {
  name: 'Paused',
}
</script>
