<template>
  <v-dialog
    v-model="$store.state.gsCommon_dialogShow.alert"
    :width="gcCommon_alert.width"
    scrollable
    persistent
  >
    <v-card min-height="128px">

      <!--  텍스트 컨텐츠  -->
      <v-card-text class="white-space-pre-wrap black--text px-4 py-5">
        {{ gcCommon_alert.text }}
      </v-card-text>

      <v-divider/>

      <!--  버튼 그룹  -->
      <v-card-actions class="pa-1">
        <v-spacer/>

        <!--  버튼: 취소(선택지)  -->
        <v-btn
          v-if="gcCommon_alert.confirm"
          @click="gmCommon_alertClose(false)"
          :color="gcCommon_alert.confirmNoColor"
          text
        >{{ gcCommon_alert.confirmNoText }}</v-btn>

        <!--  버튼: 확인(선택지)  -->
        <v-btn
          v-if="gcCommon_alert.confirm"
          @click="gmCommon_alertClose(true)"
          :color="gcCommon_alert.confirmOkColor"
          text
        >{{ gcCommon_alert.confirmOkText }}</v-btn>

        <!--  버튼: 확인(기본)  -->
        <v-btn
          v-else
          @click="gmCommon_alertClose(true)"
          :color="gcCommon_alert.defaultOkColor"
          text
        >{{ gcCommon_alert.defaultOkText }}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CommonAlert"
}
</script>

<style lang="sass" scoped>
</style>