<template>
  <v-dialog
    v-model="$store.state.gsCommon_dialogShow.alertHtml"
    fullscreen
    hide-overlay
    persistent
    scrollable
  >
    <v-card tile>

      <!--  컨트롤러  -->
      <v-card-actions class="px-1">
        <v-btn
          @click="gmCommon_alertHtmlClose"
          icon
        >
          <v-icon x-large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-card-actions>

      <!--  구분선  -->
      <v-divider class="grey"/>

      <!--  본문  -->
      <div class="c-card-text pa-3 grey lighten-3">
        <div class="pa-3 white c-html-wrap ql-editor" v-html="gcCommon_alertHtml"/>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CommonAlertHtml"
}
</script>