<template>
  <v-app>
    <!--  상태: 페이지 로딩  -->
    <div
      v-if="!gcCommon_pageLoaded"
      class="white"
    >
      <v-progress-linear indeterminate/>
    </div>

    <!--  레퍼: 컨텐츠  -->
    <v-main>
      <v-container class="pa-0 fill-height justify-center align-start">

        <!--  라우터 뷰어  -->
        <router-view/>

        <!--  시스템: 알림창  -->
        <common-alert/>

        <!--  시스템: html 팝업창  -->
        <common-alert-html/>

      </v-container>
    </v-main>

    <v-footer
      v-if="showFooter"
      class="subtitle-2 justify-center"
      color="transparent"
    >
      © {{ year }}. Inowing Inc. all rights reserved.
    </v-footer>

  </v-app>
</template>

<script>
import "./sass/overrides.sass"

import CommonAlert from "./components/CommonAlert"
import CommonAlertHtml from "./components/CommonAlertHtml"

export default {
  name: 'App',

  components: {
    CommonAlert,
    CommonAlertHtml,
  },

  watch: {
    'gcInvite_submitting'(value) {
      // 전송여부에 따른 웹 페이지 스크롤링 토글
      document.body.style.overflow = value ? 'hidden' : ''
    }
  },

  computed: {
    year() {
      return new Date().getFullYear()
    },

    showFooter() {
      const notGroupPage = this.$route.name !== 'Group'
      const notInvitePage = this.$route.name !== 'Invite'

      return notGroupPage && notInvitePage
    }
  }
}
</script>
