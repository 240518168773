<template>
  <v-list
    v-if="gcCommon_pageLoaded"
    id="s-group"
    class="elevation-4"
    max-width="640"
    min-width="320"
    width="100%"
    tag="ul"
    three-line
  >
    <!--  리스트: 초청 행사  -->
    <v-list-item
      v-for="(item, i) in gcGroup_inviteList"
      @click.stop="goToInvite(i, item)"
      :key="`invite-event-list-${i}`"
      class="px-3"
      tag="li"
    >
      <!--  이미지: 행사 로고  -->
      <v-list-item-avatar
        class="align-self-center rounded-0 my-0 mr-3 s-outline"
        width="auto"
        height="auto"
      >
        <v-img
          :src="item.event.event_image_default_src"
          class="grey lighten-1"
          width="64"
          aspect-ratio="1"
        >
          <template v-slot:placeholder>
            <v-skeleton-loader
              v-if="item.event.event_image_default_src"
              width="100%"
              height="100%"
              type="image"
            />
            <v-icon
              size="48"
              dark
            >mdi-image-off</v-icon>
          </template>
        </v-img>
      </v-list-item-avatar>

      <!--  그룹: 컨텐츠  -->
      <v-list-item-content>
        <!--  텍스트: 행사 타이틀  -->
        <v-list-item-title class="mb-1 text-h5 font-weight-bold blue--text">
          {{ item.event.event_title }}
        </v-list-item-title>

        <v-list-item-subtitle class="d-flex align-center">
          <div class="flex-grow-1">
            <!--  텍스트: 행사 장소  -->
            <div class="mb-1">{{ item.event.event_map_address }}</div>
            <!--  텍스트: 행사 날짜  -->
            <div>{{ toStringPeriod(item.event.event_started_at, item.event.event_ended_at) }}</div>
          </div>
          <v-chip :color="stateColor(item.reg_state)" text-color="white" v-text="stateText(item.reg_state)" />
        </v-list-item-subtitle>
      </v-list-item-content>

      <!--  아이콘: > 모양 (의미없음)  -->
      <v-list-item-icon class="align-self-center ml-2">
        <v-icon
          size="32"
        >mdi-chevron-right</v-icon>
      </v-list-item-icon>
    </v-list-item>

  </v-list>
</template>

<script>
export default {
  name: 'Group',

  async mounted() {
    const group_id = this.$route.params.group_id

    this.$store.state.gsCommon_pageLoaded = false
    this.$store.state.gsInvite_inviteInfo = {}
    this.$store.state.gsGroup_inviteList = []

    await this.API_getGroupList(group_id)

    this.$store.state.gsCommon_pageLoaded = true
  },

  methods: {
    // 초청 양식 페이지 이동
    goToInvite(i, item) {
      if (item.reg_state !== 1 && item.reg_state !== 2) {
        return alert(item.reg_closed_msg)
      }

      // 딥 복사
      const data = JSON.parse(JSON.stringify(this.gcGroup_inviteList[i]))

      this.$store.state.gsInvite_inviteInfo = data

      // 그룹에서 받은 gsInvite_inviteInfo 객체와
      // invite 페이지에서 받는 gsInvite_inviteInfo 객체의 구조가 다르다.
      // 따라서 강제 새로고침으로 페이지 넘겨서 다시 정보를 받게 하는 것이 속 편함
      // 18 서버 ㅈ대로 하네 ^^
      window.location.href = `/invite/${ data.event_id }`
    },

    toStringPeriod(_start = 0, _end = 0) {
      const start = new Date(_start).toLocaleDateString('ko-KR')
      const end = new Date(_end).toLocaleDateString('ko-KR')
      const arr = []

      _start && arr.push(start)
      start !== end && _end && arr.push(end)

      return arr.join(' ~ ')
    },

    stateColor(state = 0) {
      return state === 1 ? 'green' :
        state === 2 ? 'orange darken-1' : 'red'
    },

    stateText(state = 0) {
      return state === 1 ? '등록 가능' :
        state === 2 ? '예비 등록 가능' : '등록 마감'
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.s-outline
  outline: 1px solid map-get($grey, 'base')
</style>