import env from '../../env'
import { response, error } from "./handler"
import axios from "axios"

axios.defaults.withCredentials = true
axios.defaults.baseURL = env.API_BASE_URL
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded; charset=UTF-8'
axios.defaults.headers.put['Content-Type'] =
  'multipart/form-data'
axios.interceptors.response.use( response, error )

export default axios