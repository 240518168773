<script>
export default {
  name: 'InvitedAfterText',
  methods: {
    // 클릭: 신규 초청하기 (초청 완료 후 다시 초청하려는 경우)
    onClickNewOne() {
      location.reload()
    }
  },
  computed: {
    afterText() {
      const state = this.gcInvite_inviteInfo.reg_state
      return state === 1 ? this.gcInvite_inviteInfo.reg_complete_msg :
        state === 2 ? this.gcInvite_inviteInfo.wait_complete_msg :
          this.gcInvite_inviteInfo.reg_closed_msg
    },
    color() {
      const state = this.gcInvite_inviteInfo.reg_state
      return state === 1 ? 'green' :
        state === 2 ? 'orange darken-1' : 'red'
    },
    icon() {
      const state = this.gcInvite_inviteInfo.reg_state
      return state === 1 ? 'mdi-check-circle-outline' :
        state === 2 ? 'mdi-help-circle-outline' : 'mdi-minus-circle-outline'
    },
    notClosed() {
      return this.gcInvite_inviteInfo.reg_state !== 0
    }
  }
}
</script>

<template>
  <v-card-text class="text-h6 black--text d-flex flex-column text-center pt-4 pb-8">
    <div class="text-pre-wrap" v-text="afterText"/>
    <v-icon :color="color" size="128px">{{ icon }}</v-icon>

    <v-btn v-if="notClosed" block class="mt-4" color="blue" dark depressed @click="onClickNewOne">
      <v-icon>mdi-plus</v-icon>
      신규 등록
    </v-btn>

    <v-btn v-if="gcGroup_inviteList.length > 0" block class="mt-4" color="blue" outlined @click="$router.go(-1)">
      <v-icon>mdi-format-list-checkbox</v-icon>
      행사 목록으로
    </v-btn>
  </v-card-text>
</template>
