<script>
export default {
  name: 'ContactText',
  computed: {
    showContact() {
      const a = Boolean(this.gcInvite_inviteInfo.event?.agency_manager_name)
      const b = Boolean(this.gcInvite_inviteInfo.event?.agency_manager_phone)
      const c = Boolean(this.gcInvite_inviteInfo.event?.agency_manager_mail)

      return a || b || c
    }
  }
}
</script>

<template>
  <div v-if="showContact" class="text-center">
    <p class="mb-2">
      본 행사 관련 문의사항은 아래 담당자 연락처로 연락바랍니다.
    </p>

    <p class="d-flex flex-wrap justify-center s-gap-1">
      <!--  담당자: 이름  -->
      <span v-if="gcInvite_inviteInfo.event.agency_manager_name">
        담당자.
        <span class="font-weight-bold">
          {{ gcInvite_inviteInfo.event.agency_manager_name }}
        </span>
      </span>
      <!--  담당자: 연락처  -->
      <span v-if="gcInvite_inviteInfo.event.agency_manager_phone">
        Tel.
        <a :href="`tel:${ gcInvite_inviteInfo.event.agency_manager_phone }`">
          {{ gcInvite_inviteInfo.event.agency_manager_phone }}
        </a>
      </span>
      <!--  담당자: 이메일  -->
      <span v-if="gcInvite_inviteInfo.event.agency_manager_mail">
        e-mail.
        <a :href="`mailto:${ gcInvite_inviteInfo.event.agency_manager_mail }`">
          {{ gcInvite_inviteInfo.event.agency_manager_mail }}
        </a>
      </span>
    </p>
  </div>
</template>
