import Vue from 'vue'
import Vuex from 'vuex'

import stateCommon from "./stateCommon"
import stateGroup from "./stateGroup"
import stateInvite from "./stateInvite"
import mutationInvite from "./mutationInvite"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 네이밍 규칙
    // gs{Type}_{nameName}
    ...stateCommon,
    ...stateGroup,
    ...stateInvite,
  },
  mutations: {
    ...mutationInvite,
  },
  actions: {
  },
  modules: {
  }
})

export default store

export const state = store.state