<script>
export default {
  name: 'InviteStateText',
  computed: {
    color() {
      const state = this.gcInvite_inviteInfo.reg_state
      return state === 1 ? 'green' :
        state === 2 ? 'orange darken-1' : 'red'
    },
    stateText() {
      const state = this.gcInvite_inviteInfo.reg_state
      return state === 1 ? '등록 가능' :
        state === 2 ? '예비 등록 가능' : '등록 마감'
    }
  }
}
</script>

<template>
  <v-chip :color="color" class="ml-2" small text-color="white" v-text="stateText" />
</template>
