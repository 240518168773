import { state } from "../store"

import Invalid from "../views/Invalid"
import Group from "../views/Group"
import Invite from "../views/Invite"
import Paused from "../views/Paused"

export default [
  {
    path: '/',
    beforeEnter(to, from, next) {
      next({ name: 'Invalid' })
    }
  },
  {
    path: '/service-paused',
    name: 'ServicePaused',
    component: Paused,
    beforeEnter(to, from, next) {
      state.gsCommon_serviceState.service_paused !== 0 ? next() : next('/')
    }
  },
  {
    path: '/group/:group_id',
    name: 'Group',
    component: Group
  },
  {
    path: '/invite/:invite_id',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/invalid',
    name: 'Invalid',
    component: Invalid
  },
  {
    path: '*',
    beforeEnter(to, from, next) {
      next({ name: 'Invalid' })
    }
  },
]