import { state } from "../store"
import axios from "../plugins/axios"

export default {
  // 초청 그룹 리스트 요청
  async API_getGroupList(group_id = 0) {
    return axios({
      method: 'GET',
      url: `/api/v1/invite/group/${group_id}`,
    }).then( result => {
      result.code === 200 && (state.gsGroup_inviteList = result.data)
    })
  },

  // 초청 정보 요청
  async API_getInviteInfo(invite_id = 0) {
    return axios({
      method: 'GET',
      url: `/api/v1/invite/info/${invite_id}`,
    }).then( result => {
      const data = result?.data
      data && (state.gsInvite_inviteInfo = data)
    })
  },

  // 초청 정보 요청
  async API_getInviteTeam(event_id = 0) {
    return axios({
      method: 'GET',
      url: `/api/v1/cms/event/${event_id}/invite/part_info`,
    }).then( result => {
      const data = result?.data
      state.gsInvite_teamInfo = data ? data.invitee_sales_part_config : {}
      state.gsInvite_team1 = data ? data.invitee_sales_part1_list : []
      state.gsInvite_team2 = data ? data.invitee_sales_part2_list : []
      state.gsInvite_team3 = data ? data.invitee_sales_part3_list : []
      state.gsInvite_teamLabel[0] = data ? data.invitee_sales_part1_label : ''
      state.gsInvite_teamLabel[1] = data ? data.invitee_sales_part2_label : ''
      state.gsInvite_teamLabel[2] = data ? data.invitee_sales_part3_label : ''
    })
  },

  // 초청 유저 등록
  async API_postSubmit(data = {}) {
    return axios({
      method: 'POST',
      url: `/api/v1/invite/submit`,
      data,
    }).then( () => true )
  },
}
