export default {
  // 다이알로그 추가 (성공여부에 따른 Boolean 리턴)
  gmCommon_dialogOpen(key) {
    const stack = this.$store.state.gsCommon_dialogStack
    const newOne = stack.indexOf(key) === -1
    const noAlert = stack.indexOf('alert') === -1

    // 새로 추가되는 것이고 && 시스템 알림창이 비활성화 상태
    if (newOne && noAlert) {
      this.$store.state.gsCommon_dialogShow[key] = true
      this.$store.state.gsCommon_dialogStack.push(key)

      // 다이알로그 해시가 없을경우
      if (this.$route.hash !== '#dialog') {
        this.$router.push( { ...this.$route, hash: '#dialog'} )
      }
    }
    return newOne && noAlert
  },

  // 다이알로그 삭제 (성공여부에 따른 Boolean 리턴)
  gmCommon_dialogClose(key) {
    const stack = this.$store.state.gsCommon_dialogStack
    const isLast = stack[stack.length - 1] === key

    // 시도값이 스택값이 맞을경우
    if (isLast) {
      this.$store.state.gsCommon_dialogShow[key] = false
      this.$store.state.gsCommon_dialogStack.pop()

      if (stack.length === 0) {
        this.$router.go(-1)
      }
    }
    return isLast
  },

  // 알림창 오픈
  gmCommon_alertOpen(options = {}) {
    // 이미 시스템 알림창이 열려 있는 경우 리턴
    if (!this.gmCommon_dialogOpen('alert')) { return }

    const alert = this.$store.state.gsCommon_alert

    alert.width = options.width || 280
    alert.text = options.text || ''
    alert.confirmOkText = options.confirmOkText || '확인'
    alert.confirmOkColor = options.confirmOkColor || 'blue'
    alert.confirmNoText = options.confirmNoText || '취소'
    alert.confirmNoColor = options.confirmNoColor || 'blue'
    alert.defaultOkText = options.defaultOkText || '확인'
    alert.defaultOkColor = options.defaultOkColor || 'blue'
    alert.confirm = Boolean(options.confirm)
    alert.callback = typeof(options.callback) === 'function' ? options.callback : null
  },

  // 알림창 오프
  gmCommon_alertClose(ok) {
    if (this.gmCommon_dialogClose('alert')) {
      // 확인 버튼에 의한 닫기 && 콜백이 있을 경우 실행
      ok && this.gcCommon_alert.callback?.()
    }
  },

  // html 알림창 오픈
  gmCommon_alertHtmlOpen(html = '') {
    // 작업에 성공할 경우
    if (this.gmCommon_dialogOpen('alertHtml')) {
      this.$store.state.gsCommon_alertHtml = html
    }
  },

  // html 알림창 오프
  gmCommon_alertHtmlClose() {
    // 작업에 성공할 경우
    if (this.gmCommon_dialogClose('alertHtml')) {
      this.$store.state.gsCommon_alertHtml = ''
    }
  }
}