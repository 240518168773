import { state } from '../store'

export default {
  gcCommon_pageLoaded: {
    get: () => state.gsCommon_pageLoaded,
    set: value => state.gsCommon_pageLoaded = value,
  },
  gcCommon_alert: {
    get: () => state.gsCommon_alert,
    set: value => state.gsCommon_alert = value,
  },
  gcCommon_alertHtml: {
    get: () => state.gsCommon_alertHtml,
    set: value => state.gsCommon_alertHtml = value,
  },
  gcCommon_serviceState() {
    return this.$store.state.gsCommon_serviceState
  },
}