<template>
  <v-sheet
    class="mt-4 pa-4 pt-3"
    elevation="2"
  >
    <h1>404 Page not found</h1>
    <p class="body-2 mt-4 font-weight-bold">
      url 주소가 잘못되었거나,<br>
      더이상 유효하지 않습니다. <br>
      주소를 전달받은 관리자에게 문의하세요.
    </p>
  </v-sheet>
</template>

<script>
export default {
  name: "Invalid",

  mounted() {
    this.$store.state.gsCommon_pageLoaded = true
  }
}
</script>